import React from 'react';

export default class Imagen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      	cargada: false,
      	error: false
    };

    this.tipo = '';
    
  }

  componentDidMount() {
    // Creamos elemento image
    const img = new Image();
    
    // Liseners 
    img.onload = (res) => {
        // this.tipo = this._getTipo(res);
      	this.setState({
        	cargada: true
      	});
    };
    img.onerror = (err) => {
      	// console.log("Err", err);
      	this.setState({
        	error: true
      	});
    };    

    img.src = this.props.src;
  }

  _getTipo = (img) => {    
    let result = '';
    // ancho mas grande que alto
    if(img.target.width > img.target.height){
      // rectangular
      if((img.target.width * 0.5) > img.target.height){
        result = 'rectangular';
      }else{
        result = 'cuadrada';
      }
    // Cuadarada
    }else if(img.target.width === img.target.height){
      result = 'cuadrada';
    // Alta
    }else{
      result = 'alta';
    }
    // console.log("img", result);
    return result;
  }

  render() {
    //Error
    if (this.state.error) {
      return <img
        src={this.props.sinImg}
        alt={this.props.alt} 
        className="img_error"/>
    // Cargando
    } else if (!this.state.cargada) {
      return <img
        src={this.props.sinImg}
        alt={this.props.alt} 
        className = "img_loading" />
    }
    // Imagen
    return <img
      	src={this.props.src}
        alt={this.props.alt}
        className = {`img_ok ${this.tipo}`} />
  }
}