/**
 * Componente de vista Cargando
 */

import React from 'react';

export default class Loading extends React.Component {
    
    render(){
        return (<div className={`loading-mainWrapper ${this.props.class}`}>
            <p className={`message ${this.props.class}`}>{this.props.message}</p>
        </div>); 
    }
}