import React from 'react';
import "../css/general.scss"

export default class Home extends React.Component {
    
    render(){
        return (
            <div className="home-mainWrapper">
                <ul>
                    {this.props.juegos.map((juego, index) => (
                        <li key={index}>
                            <button onClick={() => this.props.handleClick(juego.api_name)} className={`home-game-btn ${juego.clase_portalista ? juego.clase_portalista : juego.clase}`}>
                                <span className={`ico icon-${juego.svg_ico}`}> </span>
                                <p className="title">{juego.api_name !== 'Clasificacion_Primera' ? juego.nombre : 'Liga LFP y Estadísticas'}</p>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
