import React from 'react';
import "../css/general.scss"
import Imagen from './Imagen';

export default class Home extends React.Component {

    // Validación de URL
    validURL(str){
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    render(){
        var QRCode = require('qrcode.react');
        
        return (
            <div id="footer" className={this.props.vista === "Home" ? 'out' : 'in'}>
                {this.props.datos && this.props.datos.logo ?
                    <div className="logo">
                        <Imagen sinImg="" src={this.props.datos.logo} />
                    </div>
                    : <div></div>
                }
                {this.props.datos && this.props.datos.texto ?
                    <div className="texto">
                        <span>{this.props.datos.texto}</span>
                    </div>
                    : <div></div>
                }
                {this.props.datos && this.props.datos.texto && this.validURL(this.props.datos.texto) ?
                    <div className="qr-code">
                        <QRCode value={this.props.datos.texto} />
                        <span className="txt">ESCANEAME</span>
                    </div>
                    : <div></div>
                }
            </div>
        );
    }
}