import React, {Component} from 'react';
import Slider from "react-slick";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import Calendar from './Calendar';
import Loading from './Loading';
import Imagen  from './Imagen';
// Funciones
import {getJuego} from './utils/ApiClient'
import {getMonth,maxDate,parseCalendarDate, checkDateMinMax} from './utils/Utils';

// Sin imagen
const sinImagen = require("../images/background.png");

// Settings del Slider
const settings = {
    centerMode: true,
    variableWidth: true,
    infinite: false,
    className: 'imgsgallery'    
};

// Función para montar el componente Lightgallery con la imagen
const PhotoItem = ({ image, group }) => (
    <LightgalleryItem group={group} src={image}>
        <span className="ico icon-zoom" />
        <Imagen sinImg={sinImagen} src={image.replace("swf", "jpg")} />
    </LightgalleryItem>
);

export default class Juego extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedDay: null,
            calendarVisible: false,
            resultado: null,
            message: `Cargando datos de ${this.props.data[0].nombre}...`,
            update: false,
            lfp: 1
        };
    }

    componentDidMount(){
        // Obtenemos la última fecha del array (la mas reciente) de la cual obtendremos el resuttado
        var date = maxDate(this.props.data[0].fecha);
        // Llamamos a obtener su resultado
        this._getJuego(date);
    }

    // Función llamada API para obtener el resultado del juego
    _getJuego = (date) => {
        this.setState({
            update: true,
        });
        // Llamamos a obtener su resultado
        getJuego(this.props.data[0].api_name, date)
            .then(data => {
                // console.log("Resultado: ", data);
                this.setState({
                    resultado: data[0],
                    update: false,
                })
            }).catch(err => {
                // console.log("Error:", err);
                this.setState({
                    message: 'No se pudieron cargar los datos'
                })
                setTimeout(()=>{
                    this.props.handleClick('Home');
                },2000)
                
            });
    }

    handlePreviousNextClick = (day, order) => {
        let date = parseCalendarDate(day);
        let newDate = '';
        let sortedDates = this.props.data[0].fecha.sort((a, b) => new Date(...a.split('-').reverse()) - new Date(...b.split('-').reverse()));
        let selectedDayIndex = sortedDates.indexOf(date)
    
        if (order === 'prev') {
            newDate = sortedDates[selectedDayIndex - 1];
        } else if (order === 'next') {
            newDate = sortedDates[selectedDayIndex + 1];
        }
        
        newDate = newDate.split('-')
        newDate = new Date(newDate[2], newDate[1] - 1, newDate[0])
        let shortNewDate = parseCalendarDate(newDate);

        // Comprobamos que esté en el array de fechas
        if (this.props.data[0].fecha.includes(shortNewDate)) {
            // Seteamos estados
            this.setState({
                selectedDay: newDate,
                calendarVisible: false
            });
            // Obtenemos resultado
            this._getJuego(shortNewDate);
        } else {
            return false;
        }
    }

    // Manejador del click en el día del calendario
    handleDayClick = (day, { selected }) => {
        var date = parseCalendarDate(day);
        // Comprobamos que esté en el array de fechas
        if (this.props.data[0].fecha.includes(date)) {
            // Seteamos estados
            this.setState({
                selectedDay: day,
                calendarVisible: false
            });
            // Obtenemos resultado
            this._getJuego(date);
        } else {
            return false;
        }
    }

    // Manejamos click en abrir calendario
    handleOpenCalendar = (_open) => {
        this.setState({ calendarVisible: _open })
    }

    // Manejamos click de botones en Clasificación LFP
    handleClickLfp = (_id) => {
        // console.log("id:",_id);
        this.setState({ lfp: _id })
    }

    render(){
        let date = maxDate(this.props.data[0].fecha);
        let parts = date.split('-');
        let fechaOk = new Date(parts[2], parts[1] - 1, parts[0]);
        let calendar = (this.state.calendarVisible) ? <Calendar handleOpenCalendar={this.handleOpenCalendar} handleDayClick={this.handleDayClick} game={this.props.data[0].clase_portalista ? this.props.data[0].clase_portalista : this.props.data[0].clase} selectedDays={ (this.props.data && this.props.data[0] && this.props.data[0].fecha) ? this.props.data[0].fecha : []} /> : '';
        let textInputDay = this.state.selectedDay ? 
            <p>{this.state.selectedDay.toLocaleDateString("es-ES", {weekday: "long", year: "numeric", month: "long", day: "numeric"})}</p> 
            : <p>{fechaOk.toLocaleDateString("es-ES", {weekday: "long", year: "numeric", month: "long", day: "numeric"})}</p>;
        let currentDay = this.state.selectedDay ? this.state.selectedDay : fechaOk

        // Si tenemos resultado
        if (this.state.resultado) {
            // Inicializamos variables
            let extra       = '';
            let elmillon    = '';
            let joker       = '';
            let combinacion = '';
            let imagenes    = '';
            let botoneslfp  = '';

            // Si tenemos combinación creamos componentes
            if(this.state.resultado.combinacion){
                // Seteamos componente COMBINACION
                switch (this.props.data[0].api_name){
                
                    case 'Loteria_Nacional':
                    case 'Loteria_Ninyo':     
                        combinacion = ( <div className="numbers">
                                            <div className={`nacional-number-wrapper primero ${this.props.data[0].api_name === 'Loteria_Ninyo' ? 'el_ninyo' : ''}`}>
                                                {this.state.resultado.combinacion.primero ? 
                                                <div className="primero-number">
                                                    <p className="numbers-title">Primer premio</p>
                                                    <ul>
                                                        <li className="number">{this.state.resultado.combinacion.primero}</li>
                                                    </ul>
                                                </div> : ''}
                                                {this.state.resultado.combinacion.fraccion || this.state.resultado.combinacion.serie ? 
                                                <div className="primero-special">
                                                    <p className="title">Premio especial</p>
                                                    <ul>
                                                        {this.state.resultado.combinacion.fraccion ?
                                                        <li className="number special">
                                                            <p className="title">Fracción</p>
                                                            <p className="number">{this.state.resultado.combinacion.fraccion}</p>
                                                        </li> : ''}
                                                        {this.state.resultado.combinacion.serie ?
                                                        <li className="number special">
                                                            <p className="title">Serie</p>
                                                            <p className="number">{this.state.resultado.combinacion.serie}</p>
                                                        </li> : ''}
                                                    </ul>
                                                </div> : ''}
                                            </div>
                                            {this.state.resultado.combinacion.segundo || this.state.resultado.combinacion.tercero ?
                                            <div className="nacional-number-wrapper others">
                                                {this.state.resultado.combinacion.segundo ?
                                                <div className="number-wrapper second">
                                                    <p className="numbers-title">Segundo premio</p>
                                                    <ul>
                                                        <li className="number">{this.state.resultado.combinacion.segundo}</li>
                                                    </ul>
                                                </div> : ''}
                                                {this.state.resultado.combinacion.tercero ?
                                                <div className="number-wrapper third">
                                                    <p className="numbers-title">Tercer premio</p>
                                                    <ul>
                                                        <li className="number">{this.state.resultado.combinacion.tercero}</li>
                                                    </ul>
                                                </div> : ''}
                                            </div> : ''}
                                            {this.state.resultado.combinacion.reintegros ?
                                            <div className="numbers reintegros">
                                                <p className="numbers-title">Reintegros</p>
                                                <ul>
                                                    {this.state.resultado.combinacion.reintegros.map((item,idx)=>{
                                                        return (<li className="number" key={idx}>{item}</li>);
                                                    })}
                                                </ul>
                                            </div> : ''}
                                        </div>);
                        break;

                    case 'Loteria_Navidad':
                        combinacion = ( <div className="numbers">
                                            <div className="nacional-number-wrapper primero">
                                                {this.state.resultado.combinacion.primero ? 
                                                <div className="primero-number">
                                                    <p className="numbers-title">Primer premio</p>
                                                    <ul>
                                                        <li className="number">{this.state.resultado.combinacion.primero}</li>
                                                    </ul>
                                                </div> : ''}                                                
                                            </div>
                                            {this.state.resultado.combinacion.segundo || this.state.resultado.combinacion.tercero ?
                                            <div className="nacional-number-wrapper others">
                                                {this.state.resultado.combinacion.segundo ?
                                                <div className="number-wrapper second">
                                                    <p className="numbers-title">Segundo premio</p>
                                                    <ul>
                                                        <li className="number">{this.state.resultado.combinacion.segundo}</li>
                                                    </ul>
                                                </div> : ''}
                                                {this.state.resultado.combinacion.tercero ?
                                                <div className="number-wrapper third">
                                                    <p className="numbers-title">Tercer premio</p>
                                                    <ul>
                                                        <li className="number">{this.state.resultado.combinacion.tercero}</li>
                                                    </ul>
                                                </div> : ''}
                                            </div> : ''}
                                            {this.state.resultado.combinacion.cuarto ?
                                            <div className="nacional-number-wrapper fourth">
                                                {this.state.resultado.combinacion.cuarto[0] ?
                                                <div className="number-wrapper">
                                                    <p className="numbers-title">Cuartos premios</p>
                                                    <ul>
                                                        {this.state.resultado.combinacion.cuarto.map((item,idx)=>{
                                                            return (<li className="number">{item}</li>);
                                                        })} 
                                                    </ul>
                                                </div> : ''}                                               
                                            </div> : ''}    
                                            {this.state.resultado.combinacion.quinto ?
                                            <div className="nacional-number-wrapper fifth">
                                                {this.state.resultado.combinacion.quinto[0] ?
                                                <div className="number-wrapper">
                                                    <p className="numbers-title">Quintos premios</p>
                                                    <ul>
                                                        {this.state.resultado.combinacion.quinto.map((item,idx)=>{
                                                            return (<li className="number">{item}</li>);
                                                        })} 
                                                    </ul>
                                                </div> : ''}                                               
                                            </div> : ''}                                            
                                        </div>);
                        break;
    
                    case 'Quiniela':
                        combinacion = this.state.resultado.combinacion.numeros ? 
                                        <div className="numbers-quiniela">                                                
                                            <ul>
                                                <div className="col col1">
                                                    {this.state.resultado.combinacion.numeros.map((item,idx)=>{
                                                        return (idx < 8)?(<li className="line" key={idx}>
                                                                    <p className="index">{idx + 1}</p>
                                                                    <p className="value">{item}</p>
                                                                </li>) : '';
                                                    })} 
                                                </div>
                                                <div className="col col2">                                               
                                                    {this.state.resultado.combinacion.numeros.map((item,idx)=>{
                                                        return (idx >= 8)?(<li className="line" key={idx}>
                                                                    <p className="index">{idx + 1}</p>
                                                                    <p className="value">{item}</p>
                                                                </li>) : '';
                                                    })} 
                                                    {this.state.resultado.combinacion.pleno15 ?
                                                    <li className="line pleno" key="15">
                                                        <p className="index">15</p>
                                                        <p className="value">{this.state.resultado.combinacion.pleno15}</p>
                                                    </li> : ''}
                                                </div>
                                            </ul>
                                        </div> : '';
                        break;

                    case 'Quinigol':
                        combinacion = this.state.resultado.combinacion.numeros ? 
                                    <div className="numbers-quinigol">
                                        <ul>
                                            <div className="col col1">
                                                {this.state.resultado.combinacion.numeros.map((item,idx)=>{
                                                    return (<li className="line" key={idx}>
                                                                <p className="index">{idx + 1}</p>
                                                                <p className="value">{item}</p>
                                                            </li>);
                                                })} 
                                            </div>
                                        </ul>
                                    </div> : '';
                        break;

                    case 'QuintuplePlus':
                        combinacion = this.state.resultado.combinacion.numeros ? 
                                <div className="numbers-quintupleplus">
                                    <p className="numbers-title">Combinación ganadora</p>
                                    <ul>
                                        <div className="col col1">
                                            {this.state.resultado.combinacion.numeros.map((item,idx)=>{
                                                return (<li className="line" key={idx}>
                                                            <p className="index">{(idx < 5) ? 'Carrera '+(idx + 1):'Carrera 5 (2º Clas.)'}</p>
                                                            <p className="value">{item}</p>
                                                        </li>);
                                            })}                                    
                                        </div>
                                    </ul>
                                </div> : '';
                        break;
                        
                    // Resto
                    default:
                        combinacion = this.state.resultado.combinacion.numeros ? 
                                        <div className="numbers">
                                            <p className="numbers-title">Combinación ganadora</p>
                                            <ul>
                                                {this.state.resultado.combinacion.numeros.map((item,idx)=>{
                                                    return (<li className="number" key={idx}>{item}</li>);
                                                })}
                                            </ul>
                                        </div> : '';
                    
                }
                // console.log("apiname:",this.props.data[0].api_name);
                // Seteamos componentes extra, joker, el millon....
                switch(this.props.data[0].api_name){
                    
                    case 'Euromillon':
                        extra = this.state.resultado.combinacion.estrellas ? 
                                <div className="extra">
                                    <ul>
                                        {this.state.resultado.combinacion.estrellas[0] ?
                                        <li className="complementario estrella">
                                            <span className="ico icon-estrella"> </span>
                                            <p className="number">{this.state.resultado.combinacion.estrellas[0]}</p>
                                        </li> : ''}
                                        {this.state.resultado.combinacion.estrellas[1] ?
                                        <li className="complementario estrella">
                                            <span className="ico icon-estrella"> </span>
                                            <p className="number">{this.state.resultado.combinacion.estrellas[1]}</p>
                                        </li> : ''}
                                    </ul>
                                </div> : '';
                        elmillon = this.state.resultado.combinacion.el_millon ? 
                                <div className="el_millon">
                                    <p className="title">EL MILLÓN</p>
                                    <p className="number">{this.state.resultado.combinacion.el_millon}</p>
                                </div> : '';
                    break;

                    case 'Eurodreams':
                        extra = this.state.resultado.combinacion.dream ? 
                        <div className="extra">
                            <ul>
                                {this.state.resultado.combinacion.dream ? 
                                <li className="complementario">
                                    <p className="title">Sueño</p>
                                    <p className="number">{this.state.resultado.combinacion.dream}</p>
                                </li> : ''}
                            </ul>
                        </div> : '';
                    break;

                    case 'Bonoloto':
                        extra = this.state.resultado.combinacion.complementario || this.state.resultado.combinacion.reintegro ?
                                <div className="extra">
                                    <ul>
                                        {this.state.resultado.combinacion.complementario ? 
                                        <li className="complementario">
                                            <p className="title">Comp.</p>
                                            <p className="number">{this.state.resultado.combinacion.complementario}</p>
                                        </li> : ''}
                                        {this.state.resultado.combinacion.reintegro ?
                                        <li className="reintegro">
                                            <p className="title">Rein.</p>
                                            <p className="number">{this.state.resultado.combinacion.reintegro}</p>
                                        </li> : ''}
                                    </ul>
                                </div> : '';
                    break;

                    case 'Gordo':
                        extra = this.state.resultado.combinacion.clave ? 
                                <div className="extra">
                                    <ul>
                                        <li className="complementario">
                                            <p className="title">Nº Clave</p>
                                            <p className="number">{this.state.resultado.combinacion.clave}</p>
                                        </li>
                                    </ul>
                                </div> : '';
                        break;

                    case 'Lototurf':
                        extra = this.state.resultado.combinacion.caballo || this.state.resultado.combinacion.reintegro ?
                            <div className="extra">
                                <ul>
                                    {this.state.resultado.combinacion.caballo ?
                                    <li className="caballo">
                                        <p className="title">Caballo</p>
                                        <p className="number">{this.state.resultado.combinacion.caballo}</p>
                                    </li> : ''}
                                    {this.state.resultado.combinacion.reintegro ?
                                    <li className="reintegro">
                                        <p className="title">Rein.</p>
                                        <p className="number">{this.state.resultado.combinacion.reintegro}</p>
                                    </li> : ''}
                                </ul>
                            </div> : '';
                    break;

                    case 'Primitiva':
                        extra = this.state.resultado.combinacion.complementario || this.state.resultado.combinacion.reintegro ? 
                                <div className="extra">
                                    <ul>
                                        {this.state.resultado.combinacion.complementario ?
                                        <li className="complementario">
                                            <p className="title">Comp.</p>
                                            <p className="number">{this.state.resultado.combinacion.complementario}</p>
                                        </li> : ''}
                                        {this.state.resultado.combinacion.reintegro ?
                                        <li className="reintegro">
                                            <p className="title">Rein.</p>
                                            <p className="number">{this.state.resultado.combinacion.reintegro}</p>
                                        </li> : ''}
                                    </ul>
                                </div> : '';
                        joker = this.state.resultado.combinacion.jocker ? 
                                <div className="joker">
                                    <p className="title">JOKER</p>
                                    <p className="number">{('0000000'+ this.state.resultado.combinacion.jocker).slice(-7)}</p>
                                </div> : '';
                    break;
                    
                default:
                    extra       = '';
                    joker       = '';
                    elmillon    = '';
                }
            }

            // Si tenemos imágenes, creamos componente de imagenes
            if(this.state.resultado.imagenes){
                // Clase si tiene varias imágenes o solo una
                let clase = this.state.resultado.imagenes.length > 1 ? 'multiple' : 'single';
                if (this.props.data[0].api_name === 'Botes' /*|| this.props.data[0].api_name === 'Clasificacion_Primera'*/)
                    clase += ' full full_h';
                if (this.props.data[0].api_name === 'Loteria_Navidad' || this.props.data[0].api_name === 'Loteria_Ninyo')
                    clase += ' full_h';
                
                // Para la liga, mostramos 2 slides, uno por botón (clasificacion y estadísticas), en los que el primero es 1 imagen (imagen) con la clasificación 
                // y el 2do son las estadísticas (el resto de imagenes, imagen_2, imagen_3 e imagen_4)
                if (this.props.data[0].api_name === 'Clasificacion_Primera'){
                    imagenes = this.state.lfp === 1 ? 
                        <div className={`right single`}>
                            <LightgalleryProvider lightgallerySettings={
                                {
                                    download: false,
                                    counter: false,
                                    thumbnail: false,
                                    fullScreen: false,
                                    loop: false,
                                    hideControlOnEnd: true,
                                    // slideEndAnimatoin: (this.state.resultado.imagenes.length > 1)
                                }
                            }>
                                <PhotoItem image={this.state.resultado.imagenes[0].replace("swf", "jpg")} group="group1" />
                            </LightgalleryProvider>                            
                        </div>
                        :
                        <div className={`right multiple`}>
                            <LightgalleryProvider lightgallerySettings={
                                    {
                                        download: false,
                                        counter: false,
                                        thumbnail: false,
                                        fullScreen: false,
                                        loop: false,
                                        hideControlOnEnd: true,
                                        // slideEndAnimatoin: (this.state.resultado.imagenes.length > 1)
                                    }
                                }>                                
                                <Slider {...settings}>
                                    {this.state.resultado.imagenes.map((p, idx) => {
                                        if(idx > 0)
                                            return <PhotoItem key={idx} image={p.replace("swf", "jpg")} group="group1" />
                                        return null;
                                    })}
                                </Slider>                                 
                            </LightgalleryProvider>                            
                        </div>;
                } else {
                    imagenes = ( <div className={`right ${clase}`}>
                            <LightgalleryProvider lightgallerySettings={
                                {
                                    download: false,
                                    counter: false,
                                    thumbnail: false,
                                    fullScreen: false,
                                    loop: false,
                                    hideControlOnEnd: true,
                                    // slideEndAnimatoin: (this.state.resultado.imagenes.length > 1)
                                }
                            }>
                                {// si tenemos mas de 1 imagen, mostramos slider, si no solo imagen
                                    this.state.resultado.imagenes.length > 1 ? <Slider {...settings}>
                                    {this.state.resultado.imagenes.map((p, idx) => (
                                        <PhotoItem key={idx} image={p.replace("swf", "jpg")} group="group1" />
                                    ))}
                                </Slider> 
                                : <PhotoItem image={this.state.resultado.imagenes[0].replace("swf", "jpg")} group="group1" /> }
                            </LightgalleryProvider>
                            {// Concatenamos calendario
                            this.props.data[0].api_name !== 'Botes' 
                                && this.props.data[0].api_name !== 'Clasificacion_Primera' 
                                && this.props.data[0].api_name !== 'Loteria_Navidad'  
                                && this.props.data[0].api_name !== 'Loteria_Ninyo' ?
                                <div className="input-calendar-wrapper">
                                    {(!checkDateMinMax(this.props.data[0].fecha, currentDay, 'min')) ? <button className="date-button date-prev" onClick={()=>this.handlePreviousNextClick(currentDay, 'prev')}>Anterior</button> : ''}
                                    <div className="input-calendar">
                                        <div className="text-input-day-wrapper">
                                            {textInputDay}
                                        </div>
                                        <button onClick={()=>this.handleOpenCalendar(true)}>Elegir fecha</button>
                                    </div>
                                    {(!checkDateMinMax(this.props.data[0].fecha, currentDay, 'max')) ? <button className="date-button date-next" onClick={()=>this.handlePreviousNextClick(currentDay, 'next')}>Siguiente</button> : ''}
                                </div> 
                                // si no debe de llevarlo,vacío
                                : ''}
                        </div> );
                }
            // Si NO tenemos imagenes, solo mostramos calendario en los casos que procede
            } else {
                imagenes = this.props.data[0].api_name !== 'Botes' && this.props.data[0].api_name !== 'Clasificacion_Primera' && this.props.data[0].api_name !== 'Loteria_Navidad' && this.props.data[0].api_name !== 'Loteria_Ninyo' ?
                            <div className={`right`}>
                                <div className="input-calendar-wrapper">
                                    {(!checkDateMinMax(this.props.data[0].fecha, currentDay, 'min')) ? <button className="date-button date-prev" onClick={()=>this.handlePreviousNextClick(currentDay, 'prev')}>Anterior</button> : ''}
                                    <div className="input-calendar">
                                        <div className="text-input-day-wrapper">
                                            {textInputDay}
                                        </div>
                                        <button onClick={()=>this.handleOpenCalendar(true)}>Elegir fecha</button>
                                    </div>
                                    {(!checkDateMinMax(this.props.data[0].fecha, currentDay, 'max')) ? <button className="date-button date-next" onClick={()=>this.handlePreviousNextClick(currentDay, 'next')}>Siguiente</button> : ''}
                                </div>
                            </div>  : '';
            }

            // Caso de clasificación LFP
            if(this.props.data[0].api_name === 'Clasificacion_Primera'){
                botoneslfp = <div className="botones">
                    <button onClick={() => this.handleClickLfp(1)} className={`boton ${this.state.lfp === 1 ? 'activo' : ''}`}>
                        <p className="title">Clasificaciones</p>
                    </button>
                    <button onClick={() => this.handleClickLfp(2)} className={`boton ${this.state.lfp === 2 ? 'activo' : ''}`}>
                        <p className="title">Estadísticas</p>
                    </button>
                </div>;
            }

            // RETURN 
            return (
                <div>

                    {calendar}

                    <div className={`game-header ${this.props.data[0].clase_portalista ? this.props.data[0].clase_portalista : this.props.data[0].clase}`}>
                        <h1> <span className = {`ico icon-${this.props.data[0].svg_ico}`} > </span> {this.props.data[0].api_name !== 'Clasificacion_Primera' ? this.props.data[0].nombre : 'Liga LFP y Estadísticas'}</h1>
                        <button onClick={() => this.props.handleClick('Home')}>Volver</button>
                    </div>
                    {!this.state.update ?
                    <div className={`games-mainWrapper ${this.props.data[0].clase}`}>
                        {this.props.data[0].api_name !== 'Botes' ?
                            <div className="left">

                                <div className="date">
                                    {(this.props.data[0].api_name !== 'Quiniela' && this.props.data[0].api_name !== 'Quinigol') ? (<p className="day">{this.state.resultado.fecha.dia}</p>) : ''}
                                    
                                    <p className="day-number">{this.state.resultado.fecha.numero} {getMonth(this.state.resultado.fecha.completa)}</p>
                                    
                                    {(this.props.data[0].api_name === 'Quiniela' || this.props.data[0].api_name === 'Quinigol') ? (<p className="jornada">Jornada {this.state.resultado.jornada}</p>) : ''}
                                </div>   

                                {combinacion}   

                                {extra}

                                {joker}

                                {elmillon}

                                {botoneslfp}

                            </div> : ''}
                        
                        {imagenes}

                    </div>:<Loading message={this.state.message} class={this.props.data[0].clase_portalista ? this.props.data[0].clase_portalista : this.props.data[0].clase}/>}

                </div>
            );
        } else {
            return(<Loading message={this.state.message} class={this.props.data[0].clase_portalista ? this.props.data[0].clase_portalista : this.props.data[0].clase}/>)
        }
    }
}