import React, {Component} from 'react';
import './App.css';
import './js/general';

// Funciones
import {getPortalista, getConfigUsuario} from './components/utils/ApiClient';

// Componentes
import ContenedorLogin  from './components/contenedor-login';
import AuthService      from './components/utils/AuthService';
import Footer           from './components/Footer';
import Home             from './components/Home';
import Juego            from './components/Juego';
import Loading          from './components/Loading';

export default class App extends Component {
  
    constructor(props) {
        super(props)
        this.state = {
            hash: null,
            update: false,
            juegos: null,
            vista: null,
            logged: true,
            usuario: null
        }
        this.Auth           = new AuthService();
        this.handleLogout   = this.handleLogout.bind(this);
        this.handleLogin    = this.handleLogin.bind(this);    
    }

    /**
     * WillMount del componente
     * 
     * - Comprobamos si está logueado o no el dispositivo, de ser
     * así obtenemos la configuración y los datos necesarios
     * 
     */
    componentWillMount() {        
        try {
            //check si estamos logueados
            this.Auth.checkLoggedIn()
            .then(res => {
                //LOGUEADO
                if (res.status === 200) {
                    // console.log("Login Ok: ", res.menssage);
                    try {
                        const profile = this.Auth.getProfile()
                        this.setState({ hash: profile.hash, logged: true })
                        this._getUserConfig();
                        this._getPortalista();
                    }
                    catch (err) {
                        console.log("error login: ", err);
                        this.handleLogout()
                    }
                //NO LOGUEADO
                } else {
                    console.log("no logueado, probando hash 1:", this.props.params.hash)
                    if (!this.props.params.hash) {
                        this.handleLogout();
                    } else {
                        //probamos login por hash
                        this.Auth.loginHash(this.props.params.hash)
                        .then(res => {
                            if(res.status === 200){
                                const profile = this.Auth.getProfile()
                                this.setState({ hash: profile.hash, logged: true })
                                this._getUserConfig();
                                this._getPortalista();
                            }else{
                                console.log("error login6");
                                document.getElementById('error-msg').innerHTML = 'Error autenticando';
                                this.handleLogout();
                            }
                        })
                        .catch(err => {
                            console.log("error login1: ", err);
                            document.getElementById('error-msg').innerHTML = err;
                        })
                    }
                }
            })
            .catch(err => {
                console.log("NO logueado, probando hash 2:", this.props.params.hash)
                if (!this.props.params.hash) {
                    console.log("error login5: ", err);
                    this.handleLogout();
                } else {
                    try {
                        //probamos login por hash
                        this.Auth.loginHash(this.props.params.hash)
                        .then(res => {   
                            if(res.status === 200){
                                const profile = this.Auth.getProfile()
                                this.setState({ hash: profile.hash, logged: true })
                                this._getUserConfig();
                                this._getPortalista();
                            }else{
                                console.log("error login7");
                                document.getElementById('error-msg').innerHTML = 'Error autenticando';
                                this.handleLogout();
                            }                        
                        })
                        .catch(err => {
                            console.log("error login2: ", err);
                            document.getElementById('error-msg').innerHTML = err;
                            this.handleLogout()
                        })
                    } catch (err) {
                        console.log("error login3: ", err);
                        document.getElementById('error-msg').innerHTML = err;
                        this.handleLogout()
                    }
                }
            })
        } catch (err) {
            console.log("error mounting: ", err)
            this.handleLogout()
            setTimeout(() => {
                document.getElementById('error-msg').innerHTML = "Error al acceder";
            }, 1000)
        }
    }

    // Función para obtener la configuración del usuario
    async _getUserConfig(){
        getConfigUsuario()
            .then(config => {
                console.log("USER: ", config);
                this.setState({
                    usuario: config
                })
            }).catch(err => {
                console.log("Error:", err);
            });
    }

    // Función para obtener el listado principal del portalista
    async _getPortalista(){
        getPortalista()
            .then(config => {
                console.log("JUEGOS: ", config);
                this.setState({
                    juegos: config,
                    vista: 'Home'
                })
            }).catch(err => {
                console.log("Error:", err);
            });
    }

    // Función para manejar el logout
    handleLogout() {
        this.Auth.logout()
        this.setState({ hash: null, logged: false })
    }

    // Funcion para manejar el login
    handleLogin(usuario, clave) {
        //si tenemos usuario y clave hacemos el login
        if (usuario != null && clave != null) {
            this.Auth.loginUserPassword(usuario, clave)
                .then(res => {
                    const profile = this.Auth.getProfile()
                    this.setState({ hash: profile.hash, logged: true })
                    this._getUserConfig();
                    this._getPortalista();
                })
                .catch(err => {
                    console.log("error login: ", err);
                    document.getElementById('error-msg').innerHTML = err;
                })
        } else {
            return false
        }
    }

    // Manejamos click en botón de juego
    handleClick = (value) => {
        this.setState({vista:value});
    }

    render() {
        // hemos hecho login y tenemos datos de juegos
        return (<div>
                    {this.state.hash ? 
                        this.state.juegos ?
                            <div>
                                {this.state.vista === "Home" ?
                                    <Home handleClick={this.handleClick} juegos={this.state.juegos}/>
                                    : <Juego handleClick={this.handleClick} data={this.state.juegos.filter((juego)=> juego.api_name === this.state.vista)}/>}
                                    {this.state.usuario && this.state.usuario.config && this.state.usuario.config.interfaz && this.state.usuario.config.interfaz.footer && this.state.usuario.config.interfaz.footer.show ? 
                                        <Footer vista={this.state.vista} datos={this.state.usuario.config.interfaz.footer} />
                                    : null}
                            </div>
                        : <Loading message="Cargando datos..." />
                    : this.state.logged ? 
                        <Loading message="Conectando..." />
                        : <ContenedorLogin params={this.props.params} login={this.handleLogin} />
                    }
                </div>);
    }
}
