
const months = {1:'Enero',2:'Febrero',3:'Marzo',4:'Abril',5:'Mayo',6:'Junio',7:'Julio',8:'Agosto',9:'Septiembre',10:'Octubre',11:'Noviembre',12:'Diciembre'};

export const getMonth = (data) => {
    var result = false;    
    // Si es una fecha, extraemos el numero del mes
    if (data.indexOf(data) !== -1) {
        var dt = data.split('-');        
        result = months[parseInt(dt[1])];
    // Si es un numero de mes
    }else{
        result = months[data];
    }
    return result;
}

// Obtenemos la fecha mayor de un array de fechas con formato "dd-mm-yyyy"
export const maxDate = (all_dates) => {
    let arrayFechas = all_dates.map((fechaActual) => {
        // Parseamos formato de fecha a "yyyy-mm-dd" para poder utilizar el date()
        var dt = fechaActual.split('-');
        fechaActual = new Date(dt[2] + "-" + dt[1] + "-" + dt[0]);
        return fechaActual;
    });
    var max = new Date(Math.max.apply(null, arrayFechas));
    return parseCalendarDate(max);
}

export const parseCalendarDate = (day) => {
    // Parseamos fecha para obtener los datos por separado
    var date = new Date(day);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var dt = date.getDate();
    // Añadimos 0 delante
    if (dt < 10) {
        dt = '0' + dt;
    }
    // Añadimos 0 delante
    if (month < 10) {
        month = '0' + month;
    }
    // Parseamos formato de fecha a "dd-mm-yyyy"
    return dt + '-' + month + '-' + year;
}

// Formato punto en número (xx.xxx , x.xxx.xxx)
export const formatPointNumber = (num) => {
    if(num)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    else    
        return false;
}

// Identificar si la fecha es máximo o mínimo del juego actual
export const checkDateMinMax = (dates, currentDate, position) => {
    let date = parseCalendarDate(currentDate);
    let sortedDates = dates.sort((a, b) => new Date(...a.split('-').reverse()) - new Date(...b.split('-').reverse()));
    let selectedDayIndex = sortedDates.indexOf(date)

    if (position === 'min' && selectedDayIndex === 0) {
        return true;
    } else if (position === 'max' && selectedDayIndex === (sortedDates.length - 1)) {
        return true;
    } else {
        return false;
    }
}