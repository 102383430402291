import $ from 'jquery';

import 'lightgallery';

$(document).ready(function(){ 
    console.log('ENTRA');
    // var element = document.getElementById('imgsgallery');


    // element.addEventListener('onBeforeSlide', function(event){
    //     var currentElement = event.detail.index;
    //     var totalElement = $('.lg-outer .lg-item').length;
    //     $('.lg-prev').removeClass('disabled')
    //     $('.lg-next').removeClass('disabled')
    //     if(currentElement === 0){
    //         $('.lg-prev').addClass('disabled')
    //     }else if(currentElement >= totalElement - 1){
    //         $('.lg-next').addClass('disabled')
    //     }
        
    //     console.log('currentElement --->' , currentElement);
    // }, false);

    // lightGallery(element,{
    //     selector: '.image-item',
    //     loop: false,
    //     download: false,
    //     counter: false
    // });

    $('.imgsgallery').lightGallery(
        {
            selector: '.image-item',
            loop: false,
            download: false,
            counter: false
        }
    );
    

    // $("#imgsgallery").slick({
    //     centerMode: true,
    //     variableWidth: true,
    //     infinite: false,
    //     nextArrow: '<a href="" class="slick-arrow slick-next"><span class="icon-arrow-right"></span></a>',
    //     prevArrow: '<a href="" class="slick-arrow slick-prev"><span class="icon-arrow-left"></span></a>',
    // });

    // $('.datepicker').pickadate({
    //     clear: ''
    // });

    // $('.calendar form > button').on('click', function(_event){
    //     _event.preventDefault();
    //     console.log('click');
    //     $('.datepicker').focus();
    // })

    // var today = new Date();
    // var dd = today.getDate();
    // var mm = today.getMonth()+1; //January is 0!
    // var yyyy = today.getFullYear();
    // if(dd<10) {
    //     dd='0'+dd;
    // } 
    // if(mm<10) {
    //     mm='0'+mm;
    // } 
    // today = dd+'/'+mm+'/'+yyyy;
    // console.log('today : ' , today)
    
    // $('.datepicker').pickadate({
    //     formatSubmit: 'yyyy/mm/dd',
    //     // min: [2015, 7, 14],
    //     container: '#container',
    //     // editable: true,
    //     closeOnSelect: false,
    //     closeOnClear: false,
    // })
    

    // var picker = $input.pickadate('picker')
    // picker.set('select', '14 October, 2014')
    // picker.open()

    // $('button').on('click', function() {
    //     picker.set('disable', true);
    // });
  });