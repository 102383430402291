import React from 'react';
import { render } from 'react-dom';

//importamos componentes
import App from './App';

import './css/login.scss';

//global donde almacenamos los parámetros que pasaremos a la app
const params = {}
//definimos en qué elemento del html volcaremos la APP
const container = document.getElementById('app')

//actualizamos medidas si resizeamos la ventana
// window.addEventListener("resize", actualizaMedidas)

//primera carga
window.onload = inicializar

//función para inicializar la app
function inicializar() {
    getParametros()	//obtenemos parámetros de la APK (pasados por url)
    pintar() //actualizamos medidas
}

//función para obtener los parámetros enviados deade la APK
function getParametros() {
    let prmstr = window.location.search.substr(1)
    return prmstr != null && prmstr !== "" ? transformaToArray(prmstr) : {}
}
//transorma parametros de la url (APK) en un array asociativo
function transformaToArray(prmstr) {
    let prmarr = prmstr.split("&")
    for (let i = 0; i < prmarr.length; i++) {
        let tmparr = prmarr[i].split("=")
        params[tmparr[0]] = tmparr[1]
    }
    
    return params
}


//función para pintar el contenido de la APP
function pintar() {
    // params['hash'] = '83b1d89d18890c7';
    console.log("Parametros URL:", params)
    render(<App params={params} />, container)
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
