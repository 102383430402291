import React, { Component } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const WEEKDAYS_SHORT = {
    es: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
};
const MONTHS = {
    es: [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre', ],
};

const WEEKDAYS_LONG = {
    es: [ 'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', ],
};

const FIRST_DAY_OF_WEEK = {
    es: 1,
};

const LABELS = {
    es: { nextMonth: 'Mes Siguiente', previousMonth: 'Mes anterior' },
};


export default class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // selectedDay: null,
            locale: 'es',
            // calendarVisible: false
        };
    }
    componentDidMount(){
        // console.log('data: ' , this.props.data)
    }

    getDates(dates){
        return dates.map((date)=>{
            var parts = date.split('-');
            return new Date(parts[2], parts[1] - 1, parts[0]);
        })
    }

    render() {
        return (
            <div className="days-calendar">
                <div className="calendar-wrapper">
                    <button onClick={() => this.props.handleOpenCalendar(false)} className="btn-open-calendar"><span className="icon-close"></span></button>
                    <DayPicker
                        locale={this.state.locale}
                        months={MONTHS[this.state.locale]}
                        weekdaysLong={WEEKDAYS_LONG[this.state.locale]}
                        weekdaysShort={WEEKDAYS_SHORT[this.state.locale]}
                        firstDayOfWeek={FIRST_DAY_OF_WEEK[this.state.locale]}
                        labels={LABELS[this.state.locale]}
                        onDayClick={this.props.handleDayClick}
                        selectedDays={[
                            ...this.getDates(this.props.selectedDays)
                        ]}
                        className={this.props.game}
                    />
                    {/* <p>
                        {this.state.selectedDay
                            ? this.state.selectedDay.toLocaleDateString()
                            : 'Please select a day 👻'}
                    </p> */}
                </div>
            </div>
        );
    }
}